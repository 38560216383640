import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import paths from "./routes/paths.js";
import Footer from "./components/Layout/Footer.js";
import Navbar from "./components/Layout/Navbar.js";
import Home from "./pages/Home.js";
import Contact from "./pages/Contact.js";
import NotFound from "./pages/NotFound.js";
import Information from "./pages/Information.js";
import About from "./pages/About.js";
import Benefits from "./pages/Benefits.js";
import Installation from "./pages/Installation.js";
import Scroll from "./components/Layout/Scroll.js";
import CustomScrollToTop from "./components/Layout/CustomScrollToTop.js";

import logo from "./assets/icons/logo.ico";

function App() {
  const links = [
    {
      url: "#",
      text: "Descubre",
      dropDown: [
        { url: paths.ABOUT, text: "Nosotros" },
        { url: paths.BENEFITS, text: "Beneficios" },
        { url: paths.INSTALLATION, text: "Instalación" },
      ],
    },
    { url: paths.INFORMATION, text: "Información" },
    { url: paths.CONTACT, text: "Contacto" },
  ];

  return (
    <div className="app">
      <Navbar links={links} />
      <div>
        <CustomScrollToTop />
        <Routes>
          <Route path={paths.HOME} element={<Home />} />
          <Route path={paths.INFORMATION} element={<Information />} />
          <Route path={paths.ABOUT} element={<About />} />
          <Route path={paths.CONTACT} element={<Contact />} />
          <Route path={paths.BENEFITS} element={<Benefits />} />
          <Route path={paths.INSTALLATION} element={<Installation />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Scroll />
      <Footer links={links} />
    </div>
  );
}

const container = document.getElementById("app");
const root = createRoot(container);

const link = document.createElement('link');
link.rel = 'shortcut icon';
link.href = logo;
document.head.appendChild(link);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Router>
        <App />
      </Router>
    </HelmetProvider>
  </React.StrictMode>
);
