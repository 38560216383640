import React from "react";
import { Link } from "react-router-dom";
import paths from "../../routes/paths";
const ImageSection = ({ title, description, image }) => {
  return (
    <div className="container grid items-center max-w-6xl mx-auto lg:grid-cols-2">
      <div className="text-center lg:text-left">
        <h1 className="block mb-8 font-sans text-5xl antialiased font-semibold leading-tight tracking-normal text-blue-gray-900 lg:text-6xl">
          {title}{" "}
        </h1>
        <p className="block font-sans text-xl antialiased font-normal leading-relaxed lg:pr-20 text-blue-gray-800">
          {description}
        </p>
        <div className="flex mt-8">
          <Link
            className="flex items-center px-6 py-3 font-sans text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none bg-slate-900 hover:bg-slate-600 focus:outline-none focus:shadow-outline"
            onClick={() => window.open(paths.PLAYSTORE, "_blank")}
          >
            DESCARGAR APP
          </Link>
        </div>
      </div>
      <div className="hidden lg:flex">
        <img src={image}
        className="rounded-lg" 
        alt="" />
      </div>
    </div>
  );
};
export default ImageSection;
