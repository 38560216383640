import React, { lazy, Suspense } from "react";
import installers from "../../assets/data/installers";
import MapView from "./MapView";

const AuthorizedInstallers = () => {
    const markers = installers.map((installer) => ({
        lat: installer.lat,
        lng: installer.lon,
        popupContent: installer.location,
    }));

  return (
    <div className="py-14">
      <h1 className="py-4 mt-10 text-3xl font-bold text-center">
        Instaladores autorizados
      </h1>
      <p className="pb-12 text-center ">
        Conoce donde se encuentran nuestros instaladores autorizados.
      </p>
      <div className="w-full py-4 h-96">
        <MapView markers={markers} />
      </div>
      <section className="relative -mt-16">
        <div className="container max-w-6xl mx-auto">
          <div className="grid grid-cols-1 gap-8 mt-8 locations sm:grid-cols-3">
            {installers.map((installers, index) => (
              <React.Fragment key={index}>
                <div className="p-6 bg-white rounded-lg shadow-md location">
                  <h2 className="mb-2 text-lg font-semibold">
                    {installers.location}
                  </h2>
                  <p className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      className="w-5 h-5 pr-2"
                    >
                      <path
                        fill="#0ea5e9"
                        d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
                      />
                    </svg>{" "}
                    {installers.contact}
                  </p>
                  <p className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="w-5 h-5 pr-2"
                    >
                      <path
                        fill="#0ea5e9"
                        d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
                      />
                    </svg>
                    <a
                      href={installers.whatsapp}
                      className="text-blue-500"
                      target="_blank"
                    >
                      {installers.phone}
                    </a>
                  </p>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AuthorizedInstallers;
