import React, { useState, useEffect } from "react";

const Scroll = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY;
    const scrollHeight = document.body.scrollHeight;

    setShowScrollToTop(scrollY > windowHeight);

    setShowScrollToBottom(scrollY < scrollHeight - windowHeight);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="fixed bottom-0 right-0 z-10 flex flex-col">
      {showScrollToTop && (
        <button
          className="p-3 m-4 bg-white rounded-full shadow-xl"
          onClick={scrollToTop}
        >
          <article className="transition-transform hover:scale-150">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.5em"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM385 231c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-71-71V376c0 13.3-10.7 24-24 24s-24-10.7-24-24V193.9l-71 71c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 119c9.4-9.4 24.6-9.4 33.9 0L385 231z"
                fill="#0ea5e9"
              />
            </svg>
          </article>
        </button>
      )}
      {showScrollToBottom && (
        <button
          className="p-3 m-4 bg-white rounded-full shadow-xl "
          onClick={scrollToBottom}
        >
          <article className="transition-transform hover:scale-150">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.5em"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM127 281c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l71 71L232 136c0-13.3 10.7-24 24-24s24 10.7 24 24l0 182.1 71-71c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 393c-9.4 9.4-24.6 9.4-33.9 0L127 281z"
                fill="#0ea5e9"
              />
            </svg>
          </article>
        </button>
      )}
    </div>
  );
};

export default Scroll;
