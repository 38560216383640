const installationProcess = {
  title: "Proceso de instalación",
  description:
    "A continuación se detalla el proceso de instalación y configuración de un sistema de rastreo y monitoreo de vehículos en tiempo real.",
  items: [
    {
      step: "Instalación del Dispositivo de Rastreo",
      description:
        "Un técnico especializado instala un dispositivo de rastreo en el vehículo, que se conecta al sistema de la empresa y transmite datos en tiempo real.",
    },
    {
      step: "Recepción de Datos en la Plataforma",
      description:
        "El dispositivo de rastreo envía constantemente información sobre la ubicación del vehículo, su velocidad, dirección y otros datos relevantes a la plataforma de la empresa.",
    },
    {
      step: "Visualización en la Plataforma de Monitoreo",
      description:
        "Los datos recibidos se muestran en la plataforma de monitoreo, donde los usuarios pueden visualizar la ubicación exacta del vehículo en un mapa interactivo.",
    },
    {
      step: "Configuración de Alertas y Notificaciones",
      description:
        "Los usuarios pueden configurar alertas y notificaciones personalizadas según sus necesidades, como alertas de velocidad, geocercas y movimientos no autorizados.",
    },
    {
      step: "Acceso a la Plataforma desde Dispositivos Móviles",
      description:
        "Los usuarios pueden acceder a la plataforma de monitoreo desde dispositivos móviles, como teléfonos inteligentes y tabletas, a través de una aplicación dedicada.",
    },
    {
      step: "Análisis de Datos y Generación de Reportes",
      description:
        "La plataforma permite realizar un análisis detallado de los datos recopilados, así como generar reportes personalizados sobre el rendimiento y la actividad de los vehículos.",
    },
    {
      step: "Intervención en Casos de Emergencia",
      description:
        "En caso de emergencia, como robos o situaciones de seguridad, la empresa puede intervenir rápidamente y coordinar con las autoridades pertinentes para recuperar el vehículo y garantizar la seguridad del conductor y la carga.",
    },
    {
      step: "Actualizaciones y Mejoras Continuas",
      description:
        "La empresa proporciona actualizaciones periódicas y mejoras continuas en su sistema de rastreo y seguridad, garantizando un servicio de alta calidad y adaptado a las necesidades cambiantes de los clientes.",
    },
  ],
};

export default installationProcess;
