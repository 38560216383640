import React from "react";
import { Link } from "react-router-dom";
const GlobalCard = ({ caption, title, description, image, button, path }) => {
  return (
    <div className="px-8 py-8 lg:py-20">
      <div className="container mx-auto">
        <div className="relative flex flex-col-reverse overflow-hidden text-white bg-sky-600 bg-clip-border shadow-sky-500/40 rounded-3xl md:flex-row md:gap-8">
          <div className="p-6 py-20 md:w-7/12">
            <p className="block font-sans text-base antialiased font-normal leading-relaxed text-white opacity-70">
              {caption}
            </p>
            <h2 className="block antialiased tracking-normal font-sans text-4xl leading-[1.3] text-white mb-5 font-normal">
              {title}
            </h2>
            <p className="block mb-4 font-sans text-xl antialiased font-normal leading-relaxed text-white">
              {description}
            </p>
            <Link
              className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-2 px-4 rounded-lg border border-white text-white hover:opacity-75 focus:ring focus:ring-white/50 active:opacity-[0.85] mt-8"
              to={path}
            >
              {button}
            </Link>
          </div>
          <div className="relative grid h-96 place-items-center md:h-auto md:w-5/12">
            <img
              src={image}
              alt="Global"
              className="object-cover w-full h-full md:absolute"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default GlobalCard;
