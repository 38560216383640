import React from "react";
import { Link } from "react-router-dom";

const InfoIconCard = ({ title, description, icon, path }) => {
  const handleClick = () => {
    window.open(path, "_blank");
  };
  return (
    <div className="w-full mb-8">
      <Link
        className="relative flex flex-col w-full min-w-0 break-words transition duration-300 ease-in-out delay-150 bg-white rounded-lg shadow-lg hover:shadow-xl hover:-translate-y-1 hover:scale-110"
        to={path}
        onClick={handleClick}
      >
        <div className="flex-auto px-4 py-5">
          <div className="inline-flex items-center justify-center w-12 h-12 p-3 mb-5 text-center text-white rounded-full shadow-lg bg-gradient-to-b from-sky-400 to-sky-600 bg-sky-400">
            {icon}
          </div>
          <h6 className="text-xl font-semibold">{title}</h6>
          <p className="mt-2 mb-4 text-blueGray-400">{description}</p>
        </div>
      </Link>
    </div>
  );
};

export default InfoIconCard;
