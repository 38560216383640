const installers = [
  {
    location: "El Oro",
    lat: -3.262169,
    lon: -79.95211,
    contact: "Te Lo Rastreo",
    phone: "+593 96 152 5440",
    whatsapp:
      "https://wa.me/593961525440?text=Estoy%20interesado%20en%20los%20servicios%20de%20Te%20lo%20rastreo%20y%20me%20encantaría%20obtener%20más%20información%20al%20respecto.%20¿Podrías%20ayudarme,%20por%20favor?%0A%0AGracias.",
  },
  {
    location: "Quito - Cumbayá",
    lat: -0.157241,
    lon: -78.470297,
    contact: "Carlos Velasco",
    phone: "+593 96 152 5440",
    whatsapp:
      "https://wa.me/593961525440?text=Hola%20Carlos,%0AEstoy%20interesado%20en%20los%20servicios%20de%20Te%20lo%20rastreo%20y%20me%20encantaría%20obtener%20más%20información%20al%20respecto.%20¿Podrías%20ayudarme,%20por%20favor?%0A%0AGracias.",
  },
  {
    location: "Ambato",
    lat: -1.253639,
    lon: -78.622458,
    contact: "ArtilRobotics",
    phone: "+593 99 830 3364",
    whatsapp:
      "https://wa.me/593998303364?text=Hola%20ArtilRobotics,%0AEstoy%20interesado%20en%20los%20servicios%20de%20Te%20lo%20rastreo%20y%20me%20encantaría%20obtener%20más%20información%20al%20respecto.%20¿Podrías%20ayudarme,%20por%20favor?%0A%0AGracias.",
  },
];

export default installers;
