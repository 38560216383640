import React from "react";

const BenefitsSection = ({ caption, title, description, items }) => {
  return (
    <div className="px-4 py-28">
      <div className="container mx-auto mb-20 text-center">
        <p className="block mb-2 font-sans text-lg antialiased font-bold text-inherit">
          {caption}
        </p>
        <h2 className="block antialiased tracking-normal font-sans text-4xl font-semibold leading-[1.3] text-blue-gray-900 mb-4">
          {title}
        </h2>
        <p className="block w-full px-4 mx-auto font-sans text-xl antialiased font-normal leading-relaxed text-blue-gray-800 md:w-10/12 lg:w-7/12 lg:px-8">
          {description}
        </p>
      </div>
      <div className="container grid grid-cols-1 mx-auto gap-y-20 md:grid-cols-2 lg:grid-cols-3">
        {items.map((item, index) => (
          <div className="relative flex flex-col text-gray-700 bg-transparent shadow-none bg-clip-border rounded-xl">
            <div className="grid justify-center p-6 text-center">
              <div className="mx-auto mb-6 grid h-12 w-12 place-items-center rounded-full bg-gradient-to-b from-sky-400 to-sky-600 bg-sky-400 p-2.5 text-white">
                {item.icon}
              </div>
              <h5 className="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                {item.name}
              </h5>
              <p className="block px-8 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BenefitsSection;
