import React from "react";
import Terms from "../../assets/data/Terms.js";
const TermsConditions = () => {
  console.log(Terms);
  const termsTitle = Terms?.termsContent?.title;
  const termsDescription = Terms?.termsContent?.description;
  const termsItems = Terms?.termsContent?.items;
  const privacityTitle = Terms?.privacyPolicyContent?.title;
  const privacityDescription = Terms?.privacyPolicyContent?.description;
  const privacityItems = Terms?.privacyPolicyContent?.items;

  return (
    <div className="relative flex flex-col text-gray-700 bg-white shadow-md bg-clip-border rounded-xl">
      <div className="p-6">
        <div>
          <h2 className="block antialiased tracking-normal font-sans text-4xl font-semibold leading-[1.3] text-blue-gray-900 mb-6">
            {termsTitle}
          </h2>
          <p className="block mb-8 font-sans text-base antialiased font-normal leading-relaxed text-blue-gray-800">
            {termsDescription}
          </p>
          {termsItems.map((item, index) => (
            <React.Fragment>
              <div>
                <h3 className="block mt-4 mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                  {item.step}
                </h3>
                <p>{item.description}</p>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="pt-4">
          <h2 className="block antialiased tracking-normal font-sans text-4xl font-semibold leading-[1.3] text-blue-gray-900 mb-6">
            {privacityTitle}
          </h2>
          <p>{privacityDescription}</p>
          {privacityItems.map((item, index) => (
            <React.Fragment>
              <div>
                <h3 className="block mt-4 mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                  {item.step}
                </h3>
                <p>{item.description}</p>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
