const paths = {
  HOME: "/",
  ABOUT: "/about",
  CONTACT: "/contact",
  INFORMATION: "/information",
  BENEFITS: "/benefits",
  INSTALLATION: "/installation",
  CLIENT_PORTAL: "https://app.telorastreo.com/",
  WHATSAPP:
    "https://wa.me/593961525440?text=Estoy%20interesado%20en%20los%20servicios%20de%20Te%20lo%20rastreo%20y%20me%20encantaría%20obtener%20más%20información%20al%20respecto.%20¿Podrías%20ayudarme,%20por%20favor?%0A%0AGracias.",
  NOTFOUND: "/notfound",
  TERMSCONDITIONS: "/terms-conditions",
  PLAYSTORE:
    "https://play.google.com/store/apps/details?id=org.telorastreo.manager",
};

export default paths;
