import React, { useState } from "react";
import logo from "../assets/images/item_bn.png";
import { Link } from "react-router-dom";
import TermsConditions from "../components/Features/TermsConditions";
import { companyEmail, companyNumber } from "../assets/data/contact";
import AuthorizedInstallers from "../components/Features/AuthorizedInstallers";

const Contact = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone:"",
    email: "",
    message: "",
  });

  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        "https://api.mailtrap.io/v1/inboxes/YOUR_INBOX_ID/messages",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer YOUR_MAILTRAP_API_KEY`,
          },
          body: JSON.stringify({
            from_email: `${formData.email}`,
            to: "arpoma167@gmail.com",
            subject: "INFORMACIÓN | TE LO RASTREO",
            html: `
              <p>Name: ${formData.firstName} ${formData.lastName}</p>
              <p>Email: ${formData.email}</p>
              <p>Message: ${formData.message}</p>
            `,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send email");
      }

      setMessage("Email sent successfully!");
      setFormData({ firstName: "", lastName: "", email: "", message: "" });
    } catch (error) {
      console.error("Error sending email:", error);
      setMessage("Failed to send email. Please try again later.");
    }
  };

  return (
    <div className="py-20">
      <div className="container max-w-6xl mx-auto mb-5 text-center md:mb-20">
        <h1 className="block mb-4 font-sans text-5xl antialiased font-semibold leading-tight tracking-normal text-blue-gray-900">
          Contáctanos
        </h1>
        <p className="block antialiased font-sans text-xl font-normal leading-relaxed text-inherit mx-auto !text-gray-500">
          Obten más información sobre nuestros productos y servicios
        </p>
      </div>
      <div className="container max-w-6xl mx-auto">
        <div className="relative flex flex-col text-gray-700 bg-white border shadow-md bg-clip-border rounded-xl border-gray/50">
          <div className="grid grid-cols-1 p-6 md:p-10 lg:grid-cols-2 md:gap-28">
            <div className="w-full h-full p-5 mt-8 md:mt-0 md:px-10">
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 mb-8 lg:grid-cols-2">
                  <div className="relative w-full min-w-[200px] h-12 !min-w-full mb-3 md:mb-0">
                    <input
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      className="w-full h-full px-px pt-5 pb-2 font-sans text-sm font-normal transition-all bg-transparent border-b peer text-blue-gray-700 outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 placeholder-shown:border-blue-gray-200 border-blue-gray-200 focus:border-gray-900"
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-2.5 text-sm peer-focus:text-sm after:content[' '] after:block after:w-full after:absolute after:-bottom-2.5 left-0 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight text-gray-500 peer-focus:text-gray-900 after:border-gray-500 peer-focus:after:border-gray-900">
                      Nombre
                    </label>
                  </div>
                  <div className="relative w-full min-w-[200px] h-12 !min-w-full mb-3 md:mb-0">
                    <input
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      className="w-full h-full px-px pt-5 pb-2 font-sans text-sm font-normal transition-all bg-transparent border-b peer text-blue-gray-700 outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 placeholder-shown:border-blue-gray-200 border-blue-gray-200 focus:border-gray-900"
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-2.5 text-sm peer-focus:text-sm after:content[' '] after:block after:w-full after:absolute after:-bottom-2.5 left-0 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight text-gray-500 peer-focus:text-gray-900 after:border-gray-500 peer-focus:after:border-gray-900">
                      Apellido
                    </label>
                  </div>
                </div>
                <div className="relative w-full min-w-[200px] h-12 !min-w-full mb-8">
                  <input
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full h-full px-px pt-5 pb-2 font-sans text-sm font-normal transition-all bg-transparent border-b peer text-blue-gray-700 outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 placeholder-shown:border-blue-gray-200 border-blue-gray-200 focus:border-gray-900"
                  />
                  <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-2.5 text-sm peer-focus:text-sm after:content[' '] after:block after:w-full after:absolute after:-bottom-2.5 left-0 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight text-gray-500 peer-focus:text-gray-900 after:border-gray-500 peer-focus:after:border-gray-900">
                    Teléfono
                  </label>
                </div>
                <div className="relative w-full min-w-[200px] h-12 !min-w-full mb-8">
                  <input
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full h-full px-px pt-5 pb-2 font-sans text-sm font-normal transition-all bg-transparent border-b peer text-blue-gray-700 outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 placeholder-shown:border-blue-gray-200 border-blue-gray-200 focus:border-gray-900"
                  />
                  <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-2.5 text-sm peer-focus:text-sm after:content[' '] after:block after:w-full after:absolute after:-bottom-2.5 left-0 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight text-gray-500 peer-focus:text-gray-900 after:border-gray-500 peer-focus:after:border-gray-900">
                    Email
                  </label>
                </div>
                <div className="relative w-full min-w-[200px] !min-w-full mb-10">
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="peer w-full h-full min-h-[100px] bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 resize-y disabled:bg-blue-gray-50 disabled:border-0 disabled:resize-none transition-all border-b placeholder-shown:border-blue-gray-200 text-sm px-px pt-5 pb-2 border-blue-gray-200 focus:border-gray-900 !resize-none"
                  ></textarea>
                  <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-2.5 text-sm peer-focus:text-sm after:content[' '] after:block after:w-full after:absolute after:-bottom-1 left-0 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight text-gray-500 peer-focus:text-gray-900 after:border-gray-500 peer-focus:after:border-gray-900">
                    Mensaje
                  </label>
                </div>
                <div className="inline-flex items-center">
                  <label className="relative flex items-center cursor-pointer p-3 rounded-full -ml-2.5">
                    <input
                      type="checkbox"
                      className="peer relative appearance-none w-5 h-5 border rounded-md border-blue-gray-200 cursor-pointer transition-all before:content[''] before:block before:bg-blue-gray-500 before:w-12 before:h-12 before:rounded-full before:absolute before:top-2/4 before:left-2/4 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 hover:before:opacity-10 before:transition-opacity checked:bg-gray-900 checked:border-gray-900 checked:before:bg-gray-900"
                    ></input>
                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                      >
                        <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"></path>
                      </svg>
                    </span>
                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"></span>
                  </label>
                  <label className="mt-px font-light text-gray-700 cursor-pointer select-none">
                    <p className="flex items-center font-sans text-sm antialiased font-normal leading-normal text-gray-700">
                      Estoy de acuerdo con los
                      <Link
                        to="#"
                        className="ml-1 font-medium transition-colors hover:text-gray-900"
                        onClick={openModal}
                      >
                        términos y condiciones
                      </Link>
                    </p>
                  </label>
                  {modalOpen && (
                    <div className="fixed inset-0 z-50 flex justify-center bg-gray-800 bg-opacity-50">
                      <div className="container flex flex-col items-end max-w-6xl p-6 mx-auto my-8 overflow-auto bg-white rounded-lg shadow-lg">
                        {" "}
                        {/* Agregamos 'items-end' */}
                        <button
                          onClick={closeModal}
                          className="my-2 text-gray-500 top-4 right-4 hover:text-gray-300"
                        >
                          Cerrar
                        </button>
                        <TermsConditions />
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-end w-full">
                  <button
                    type="submit"
                    className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none w-full md:w-fit"
                  >
                    Enviar Mensaje
                  </button>
                </div>
              </form>
              {message && <p className="text-center">{message}</p>}
            </div>
            <div className="w-full h-full p-5 py-8 bg-gray-900 rounded-lg md:p-16">
              <h4 className="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-white">
                Información de Contacto
              </h4>
              <p className="block antialiased font-sans font-normal text-inherit  text-base !text-gray-500">
                {" "}
                Llena el formulario y obtendras una respuesta en menos de 24
                horas
              </p>
              <p className="block mx-auto mb-8 font-sans antialiased font-semibold leading-snug tracking-normal text-white text-md">
                {companyEmail}
              </p>
              <div className="">
                <img src={logo} className="h-20" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AuthorizedInstallers />
    </div>
  );
};

export default Contact;
