const faq = [
  {
    title: "¿Qué es un rastreador GPS?",
    content:
      "Un rastreador GPS es un dispositivo que utiliza el sistema de posicionamiento global (GPS) para determinar la ubicación precisa de un vehículo, persona u otro activo en tiempo real. Los rastreadores GPS son ampliamente utilizados en la industria del transporte y la logística para mejorar la eficiencia operativa y la seguridad de los activos.",
  },
  {
    title: "¿Qué es Te Lo Rastreo y qué servicios ofrece?",
    content:
      "Te Lo Rastreo es una empresa especializada en servicios de monitoreo de vehículos mediante dispositivos GPS. Ofrecemos soluciones de seguimiento y seguridad para vehículos particulares y activos móviles, brindando tranquilidad y control a nuestros clientes.",
  },
  {
    title:
      "¿Cómo funciona el servicio de monitoreo de vehículos de Te Lo Rastreo?",
    content:
      "Nuestro servicio de monitoreo de vehículos utiliza dispositivos GPS instalados en los vehículos para rastrear su ubicación en tiempo real. Los clientes pueden acceder a esta información a través de nuestra plataforma web o aplicación móvil, lo que les permite visualizar la ubicación de sus vehículos, recibir alertas y acceder a informes detallados sobre el uso y el rendimiento de los mismos.",
  },
  {
    title:
      "¿Cuáles son los beneficios de utilizar un rastreador GPS en mi vehículo?",
    content:
      "Los rastreadores GPS ofrecen una serie de beneficios para los propietarios de vehículos, incluyendo la capacidad de rastrear la ubicación de sus vehículos en tiempo real, recibir alertas de movimiento no autorizado, mejorar la eficiencia operativa y la seguridad de los activos, y reducir los costos operativos asociados con el mantenimiento y la gestión de flotas.",
  },
  {
    title: "¿Cómo puedo contratar los servicios de Te Lo Rastreo?",
    content:
      "Para contratar nuestros servicios de monitoreo de vehículos, simplemente contáctanos a través de nuestro sitio web, por teléfono o por correo electrónico. Nuestro equipo estará encantado de proporcionarte más información sobre nuestros servicios y ayudarte a encontrar la solución de rastreo vehicular que mejor se adapte a tus necesidades.",
  },
  {
    title: "¿Te Lo Rastreo opera únicamente en Ecuador?",
    content:
      "Sí, actualmente Te Lo Rastreo opera exclusivamente en Ecuador, brindando servicios de monitoreo de vehículos a clientes en todo el país. Sin embargo, estamos en constante expansión y evaluamos oportunidades para ofrecer nuestros servicios en nuevos mercados en el futuro.",
  },
];
export default faq;
