import React from "react";

const FeatureSection = ({ caption, title, description, items }) => {
  return (
    <div>
      <section className="px-4 py-28">
        <div className="container max-w-6xl mx-auto mb-20 text-center">
          <p className="block mb-2 font-sans text-base antialiased font-bold leading-relaxed uppercase text-blue-gray-900">
            {caption}
          </p>
          <h1 className="block mb-4 font-sans text-5xl antialiased font-semibold leading-tight tracking-normal text-blue-gray-900">
            {title}
          </h1>
          <p className="block antialiased font-sans text-xl font-normal leading-relaxed text-inherit mx-auto w-full px-4 !text-gray-500 lg:w-11/12 lg:px-8">
            {description}
          </p>
        </div>
        <div className="container grid max-w-6xl grid-cols-1 gap-4 mx-auto gap-y-12 md:grid-cols-2">
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <div className="relative flex flex-col text-gray-700 bg-transparent shadow-none bg-clip-border rounded-xl">
                <div className="grid justify-start p-6">
                  <div className="mb-4 block h-12 w-12 place-content-center rounded-lg bg-gray-900 p-2.5 text-left text-white">
                  {item.icon}
                  </div>
                  <h5 className="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                    {item.title}
                  </h5>
                  <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-normal !text-gray-500">
                    {item.description}
                  </p>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </section>
    </div>
  );
};

export default FeatureSection;
