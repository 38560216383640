const Terms= {
  termsContent: {
    title: "Términos de Uso",
    description:
      "El presente documento establece los términos y condiciones generales que regulan el uso de las plataformas web y aplicaciones móviles de Te Lo Rastreo en Ecuador. Estos servicios permiten a los clientes acceder a servicios de monitoreo de vehículos mediante dispositivos GPS ofrecidos por Te Lo Rastreo, de acuerdo al tipo de servicio contratado.",
    items: [
      {
        step: "Aceptación de los Términos y Condiciones",
        description:
          "El cliente, ya sea persona natural o jurídica, que contrate el servicio de monitoreo de vehículos con Te Lo Rastreo, acepta los términos y condiciones aquí descritos. La utilización de la plataforma web y/o la descarga de la aplicación móvil implica la aceptación de estos términos, que tienen carácter obligatorio y vinculante.",
      },
      {
        step: "Acceso a los Servicios",
        description:
          "Los servicios de Te Lo Rastreo estarán disponibles según lo establecido en el contrato de servicios suscrito entre el cliente y Te Lo Rastreo. El acceso a estos servicios estará restringido a los clientes con servicios activos. El cliente podrá acceder a los servicios contratados a través de la plataforma web y/o la aplicación móvil correspondiente, utilizando el correo electrónico registrado y una clave de seguridad personal elegida por el cliente. Este último es responsable de mantener la confidencialidad de su clave de seguridad.",
      },
      {
        step: "Tratamiento de la Información",
        description:
          "La información generada por los vehículos con servicio de monitoreo activo se procesa y almacena en servidores seguros. El cliente será responsable del uso que haga de esta información.",
      },
      {
        step: "Autorización para el Tratamiento de Datos",
        description:
          "El cliente autoriza a Te Lo Rastreo a procesar los datos generados por los vehículos con servicio de monitoreo activo, con el fin de prestar los servicios contratados. Te Lo Rastreo se compromete a no compartir esta información con terceros, salvo en los casos permitidos por la ley.",
      },
      {
        step: "Actualizaciones del Servicio",
        description:
          "Te Lo Rastreo podrá realizar actualizaciones a la plataforma web y/o la aplicación móvil, las cuales deberán ser actualizadas por el cliente en su ordenador y/o equipo móvil para acceder a las mejoras del servicio.",
      },
      {
        step: "Disponibilidad del Servicio",
        description:
          "Te Lo Rastreo no garantiza un acceso permanente e ininterrumpido a la plataforma web y/o la aplicación móvil, ya que factores externos pueden afectar la disponibilidad del servicio.",
      },
      {
        step: "Modificaciones a los Términos y Condiciones",
        description:
          "Te Lo Rastreo se reserva el derecho de modificar los términos y condiciones en cualquier momento, notificando al cliente mediante la publicación de una versión actualizada en el sitio web, con indicación de la fecha de la última modificación. La aceptación del cliente será necesaria para continuar utilizando los servicios.",
      },
      {
        step: "Contacto",
        description:
          "Ante cualquier duda sobre estos términos y condiciones, el cliente puede contactar a Te Lo Rastreo a través del apartado de contacto de la plataforma web ",
      },
    ],
  },
  privacyPolicyContent: {
    title: "Politica de Privacidad",
    description:
      "El cliente reconoce y acepta que, para la ejecución del servicio contratado, Te Lo Rastreo requiere o podría requerir la recopilación, uso y tratamiento de algunos datos personales. Por lo tanto, otorga a Te Lo Rastreo su consentimiento libre, previo, expreso, informado e inequívoco para incorporar en sus bases de datos los datos personales suministrados y para darles el tratamiento necesario, de conformidad con los términos y finalidades establecidas en la legislación vigente y demás normas aplicables.",
    items: [
      {
        step: "Principios de Tratamiento de Datos",
        description:
          "Te Lo Rastreo se compromete a respetar en todo momento los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, pertinencia, proporcionalidad, seguridad, nivel de protección adecuado, transparencia, acceso y circulación restringida, confidencialidad, minimización y responsabilidad en el tratamiento de los datos personales del cliente.",
      },
      {
        step: "Almacenamiento de Datos",
        description:
          "Los datos personales serán almacenados en bancos de datos personales que cumplan con lo establecido en las normas legales vigentes en materia de protección de datos.",
      },
      {
        step: "Confidencialidad y Seguridad",
        description:
          "Te Lo Rastreo se compromete a mantener en estricta confidencialidad y no divulgar los datos personales del cliente, ni hacer uso de estos para fines distintos a aquellos para los que fueron comunicados, sin la previa autorización por escrito del cliente.",
      },
      {
        step: "Derechos del Titular de los Datos",
        description:
          "Te Lo Rastreo garantiza al cliente la posibilidad de ejercer sus derechos de información, acceso, inclusión, eliminación, oposición, portabilidad, suspensión del tratamiento, rectificación y actualización de sus datos personales.",
      },
      {
        step: "Notificación de Vulneraciones de Seguridad",
        description:
          "Te Lo Rastreo informará de manera inmediata, y a más tardar en el plazo de 2 días, al cliente en caso de que ocurra una vulneración de seguridad que ponga en riesgo los datos personales recibidos.",
      },
      {
        step: "Medidas de Seguridad",
        description:
          "Te Lo Rastreo tomará las medidas de seguridad tecnológicas, físicas y administrativas necesarias para prevenir el uso y/o divulgación indebida de los datos personales del cliente.",
      },
      {
        step: "Retención y Destrucción de Datos",
        description:
          "Te Lo Rastreo se compromete a destruir los datos personales del cliente una vez finalizado el contrato o, de ser necesario seguir tratándolos, lo hará solo mientras sea legalmente necesario y, en la medida de lo posible, de manera anonimizada.",
      },
    ],
  },
};

export default Terms;