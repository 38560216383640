import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo_bn.png";
import paths from "../../routes/paths";

const Footer = ({ links }) => {
  return (
    <footer className="py-20 bg-slate-900">
      <div className="container flex flex-col items-center justify-between max-w-6xl px-6 mx-auto lg:flex-row">
        <div className="">
          <Link to={paths.HOME} className="flex items-center flex-shrink-0 mb-4 text-white lg:mb-0">
            <img src={logo} alt="Logo" className="h-20" />
            <span className="text-base font-medium text-gray-300 rounded-md hover:text-sky-500">
              Te Lo Rastreo
            </span>
          </Link>
        </div>

        <div className="flex flex-col justify-end flex-grow lg:flex-row">
          {links.map((link, index) => (
            <div key={index} className="mt-4 lg:mt-0 lg:ml-4">
              <Link
                to={link.url}
                className="block font-bold text-gray-300 hover:text-white"
              >
                {link.text}
              </Link>
              {link.dropDown && (
                <div className="mt-2 text-sm">
                  {link.dropDown.map((item, idx) => (
                    <Link
                      key={idx}
                      to={item.url}
                      className="block my-2 text-gray-300 hover:text-white"
                    >
                      {item.text}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
