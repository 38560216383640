import React from "react";
import Stepper from "../components/Features/Stepper";
import instalationProcess from "../assets/data/installationProcess.js";
import CollapsibleSection from "../components/Features/CollapsibleSection.js";
import faq from "../assets/data/faq.js";
import HelmetWrapper from "../components/HelmetWrapper";

const Installation = () => {
  return (
    <div>
      <HelmetWrapper
        title="Instalación - Telorastreo"
        description="Aprende cómo instalar y configurar los dispositivos de rastreo de Telorastreo en tus vehículos. Sigue nuestros pasos sencillos para empezar a proteger tus activos hoy mismo."
      />
      <Stepper
        title={instalationProcess.title}
        description={instalationProcess.description}
        items={instalationProcess.items}
      />
      <div className="w-full my-16">
        <div className="container max-w-6xl px-4 mx-auto">
          <div className="flex flex-wrap justify-center mb-4 -mx-4">
            <div className="relative w-full px-4 text-center lg:w-8/12">
              <h6 className="mb-2 text-lg font-bold uppercase text-sky-500">
                FAQ{" "}
              </h6>
              <h2 className="mt-0 mb-1 text-4xl font-bold text-blueGray-700">
                Preguntas Frecuentes
              </h2>
              <p className="mt-2 mb-4 text-xl leading-relaxed text-blueGray-400">
                ¡Encuentra respuestas a las preguntas más comunes sobre Te Lo
                Rastreo y nuestros servicios! Si no encuentras lo que buscas, no
                dudes en ponerte en contacto con nuestro equipo de atención al
                cliente. Estaremos encantados de ayudarte.
              </p>
            </div>
          </div>
          <div className="flex flex-wrap -mx-4">
            <div className="relative w-full px-4">
              <div className="my-12">
                {faq.map((item, index) => (
                  <React.Fragment key={index}>
                    <CollapsibleSection
                      title={item.title}
                      content={item.content}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Installation;
