import React, { useState, useEffect } from "react";
import paths from "../routes/paths";
import Hero from "../components/Features/Hero";
import FeatureSection from "../components/Features/FeatureSection";
import MobileSection from "../components/Features/MobileSection";
import ImageCard from "../components/Features/ImageCard";
import GlobalCard from "../components/Features/GlobalCard";
import InfoIconCard from "../components/Features/InfoIconCard";
import features from "../assets/data/features";
import hero_right from "../assets/images/hero_right1.png";
import mobile_app from "../assets/images/mobile_app1.png";
import mobileMockup from "../assets/images/mockup_mobile.png";
import mobileMockupUser from "../assets/images/mockup_mobile1.png";
import itemBN from "../assets/images/item_bn.png";
import AuthorizedInstallers from "../components/Features/AuthorizedInstallers";
import HelmetWrapper from "../components/HelmetWrapper";

const Home = () => {
  const itemData = {
    title: "Descarga nuestra aplicación móvil",
    description: "Localiza y gestiona tus vehículos desde nuestras plataformas",
    items: [
      {
        title: "191",
        description: "Clientes Satisfechos",
      },
      {
        title: "191+",
        description: "Instalaciones Realizadas",
      },
      {
        title: "24/7",
        description: "Soporte",
      },
    ],
  };
  const itemCard = {
    caption: "Descubre una nueva era en la protección de vehículos",
    title: "Mantén seguros y supervisa tus vehículos",
    description:
      "Localice todos sus vehículos en tiempo real las 24 horas del día, determine la dirección exacta indicando si están encendidos, apagados o en movimiento, verifique la velocidad y estado de los vehículos.",
    image: itemBN,
    paths: paths.WHATSAPP,
    button: "Contacta a un asesor",
  };
  const clientCard = {
    title:
      "Dispositivo de seguimiento vehicular encubierto para seguridad y localización",
    description:
      "Dispositivo de seguimiento vehicular encubierto, instalado dentro de vehículos o motocicletas para facilitar su localización en caso de robo. Este dispositivo es aplicable a una amplia variedad de vehículos y maquinaria.",
    image: mobile_app,
    item: [
      {
        comment:
          "En Telorastreo, estamos comprometidos con la seguridad y el control de vehículos en Ecuador. Nuestro objetivo es brindar tranquilidad a conductores particulares y empresas, ofreciendo soluciones de rastreo vehicular confiables y avanzadas.",
        author: "Te Lo Rastreo 2020",
        image: mobileMockup,
        button: "Conoce nuestros precios",
        path: paths.INFORMATION,
      },
      {
        comment:
          "Estamos comprometidos a superar las expectativas de nuestros clientes y a seguir siendo líderes en innovación y calidad en el campo de rastreo y seguridad vehicular en Ecuador.",
        author: "Te Lo Rastreo 2020",
        image: mobileMockupUser,
        button: "Contáctanos",
        path: paths.CONTACT,
      },
    ],
  };

  const itemInfoCard = {
    title: "¿Por qué elegirnos?",
    description:
      "Descubre las razones para elegir nuestros servicios de rastreo vehicular.",
    price: {
      title: "Conoce nuestros precios",
      description:
        "Explora nuestros planes y precios competitivos diseñados para satisfacer tus necesidades de rastreo vehicular.",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path
            fill="#f8f9fc"
            d="M160 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.1-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7 0 0C119.4 279.3 84.4 270 58.4 253c-14.2-9.3-27.5-22-35.8-39.6c-8.4-17.9-10.1-37.9-6.1-59.2C23.7 116 52.3 91.2 84.8 78.3c13.3-5.3 27.9-8.9 43.2-11V32c0-17.7 14.3-32 32-32z"
          />
        </svg>
      ),
      path: paths.INFORMATION,
    },
    installation: {
      title: "Proceso de Instalación",
      description:
        "Aprende sobre nuestro proceso de instalación sencillo y eficiente para empezar a proteger tus vehículos rápidamente.",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path
            fill="#f8f9fc"
            d="M0 96v320c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V96c0-26.5-21.5-48-48-48H48C21.5 48 0 69.5 0 96zm400 32h-64v-32h64v32zm-64 320H48V128h288v320zm-96-96v-64h64v64h-64z"
          />
        </svg>
      ),
      path: paths.INFORMATION,
    },
    benefit: {
      title: "Beneficios",
      description:
        "Descubre los beneficios de elegir nuestros servicios, desde seguridad mejorada hasta eficiencia operativa.",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path
            fill="#f8f9fc"
            d="M224 0C100.3 0 0 100.3 0 224c0 39.7 10.6 76.9 28.9 109.3c1.3 2.5 3.7 4.7 6.6 4.7c.9 0 1.8-.2 2.7-.5c35.6-13.9 76.2-21.5 118.8-21.5c42.6 0 83.2 7.6 118.8 21.5c.9 .3 1.8 .5 2.7 .5c2.9 0 5.3-2.2 6.6-4.7C437.4 300.9 448 263.7 448 224C448 100.3 347.7 0 224 0zM112 256c-8.8 0-16-7.2-16-16s7.2-16 16-16s16 7.2 16 16s-7.2 16-16 16zm48 0c-8.8 0-16-7.2-16-16s7.2-16 16-16s16 7.2 16 16s-7.2 16-16 16zm48 0c-8.8 0-16-7.2-16-16s7.2-16 16-16s16 7.2 16 16s-7.2 16-16 16zm48 0c-8.8 0-16-7.2-16-16s7.2-16 16-16s16 7.2 16 16s-7.2 16-16 16z"
          />
        </svg>
      ),
      path: paths.CONTACT,
    },
  };

  return (
    <div>
      <HelmetWrapper
        title="Telorastreo - Rastreo, Seguridad y Control Vehicular en Ecuador"
        description="Telorastreo es una empresa líder en el sector de rastreo, seguridad y control vehicular en Ecuador. Con años de experiencia, nos esforzamos por proporcionar soluciones de rastreo satelital en tiempo real, seguridad vehicular y gestión de flotas."
      />
      <Hero
        image={hero_right}
        title="¡Protegiendo a Ecuador, un vehículo a la vez!"
        description="Descubre una nueva era en la protección de vehículos. Con nuestra avanzada tecnología de rastreo satelital y seguridad, puedes mantener tus activos seguros en todo momento. Desde una vigilancia continua hasta alertas instantáneas, estamos aquí para brindarte tranquilidad y protección. Únete a nosotros en nuestro viaje hacia un Ecuador más seguro y protegido en la carretera"
        button="Solicitar Información"
        path={paths.WHATSAPP}
      />
      <div className="mt-28">
        <h2 className="text-4xl font-bold text-center text-blueGray-800">
          {itemInfoCard.title}
        </h2>
        <p className="mt-2 mb-4 text-lg leading-relaxed text-center text-blueGray-400">
          {itemInfoCard.description}
        </p>
        <div className="container grid max-w-6xl grid-cols-1 gap-6 mx-auto gap-y-12 md:grid-cols-3">
          <InfoIconCard
            title={itemInfoCard.price.title}
            description={itemInfoCard.price.description}
            icon={itemInfoCard.price.icon}
            path={itemInfoCard.price.path}
          />
          <InfoIconCard
            title={itemInfoCard.installation.title}
            description={itemInfoCard.installation.description}
            icon={itemInfoCard.installation.icon}
            path={itemInfoCard.installation.path}
          />
          <InfoIconCard
            title={itemInfoCard.benefit.title}
            description={itemInfoCard.benefit.description}
            icon={itemInfoCard.benefit.icon}
            path={itemInfoCard.benefit.path}
          />
        </div>
      </div>
      <FeatureSection
        caption={features.caption}
        title={features.title}
        description={features.description}
        items={features.items}
      />
      <ImageCard
        title={clientCard.title}
        description={clientCard.description}
        items={clientCard.item}
      />

      <MobileSection image={mobile_app} item={itemData} />

      <GlobalCard
        caption={itemCard.caption}
        title={itemCard.title}
        description={itemCard.description}
        image={itemCard.image}
        button={itemCard.button}
        path={itemCard.paths}
      />
      <AuthorizedInstallers />
    </div>
  );
};

export default Home;
