import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import logo_bn from "../../assets/images/logo_bn.png";
import paths from "../../routes/paths";

const Navbar = ({ links }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMobile, setIsOpenMobile] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const closeDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", closeDropdown);

    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  return (
    <nav className="bg-slate-900">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
            <Link to="/" className="flex items-center">
              <img src={logo_bn} alt="Logo" className="h-10 mr-2" />
              <span className="text-base font-medium text-gray-300 rounded-md hover:text-sky-500">
                Te Lo Rastreo
              </span>
            </Link>
          </div>
          <div className="flex items-center">
            <div className="hidden md:block">
              <div className="flex items-baseline ml-10 space-x-4">
                {links.map((link, index) => (
                  <React.Fragment key={index}>
                    {link.dropDown ? (
                      <div className="relative group" ref={dropdownRef}>
                        <button
                          className="flex items-center px-3 py-2 font-medium text-gray-300 rounded-md hover:text-white focus:outline-none"
                          onClick={toggleDropdown}
                        >
                          <span className="py-2 font-medium text-gray-300 border-b border-transparent hover:border-gray-100 hover:border-b hover:border-solid hover:border-opacity-50">
                            {link.text}
                          </span>
                          <svg
                            className="w-5 h-5 ml-2"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        <div
                          className={`absolute left-0 z-20 ${
                            isOpen ? "block" : "hidden"
                          } w-48  origin-top-left bg-white rounded-sm shadow-lg`}
                        >
                          {link.dropDown.map((item, index) => (
                            <Link
                              key={index}
                              to={item.url}
                              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                            >
                              {item.text}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <Link
                        to={link.url}
                        className="px-3 py-2 font-medium text-gray-300 transition duration-200 ease-in-out border-b border-transparent hover:border-gray-100 hover:border-b hover:border-solid hover:border-opacity-50"
                      >
                        {link.text}
                      </Link>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <Link
              to={paths.CLIENT_PORTAL}
              className="px-3 py-2 text-base font-medium text-white transition duration-200 ease-in-out border bg-sky-500 border-sky-500 rounded-3xl hover:border-sky-700 hover:bg-sky-700 hover:border hover:rounded-3xl hover:border-solid hover:border-opacity-50"
            >
              Portal de Clientes
            </Link>
          </div>
          <div className="flex -mr-2 md:hidden">
            <button
              onClick={() => setIsOpenMobile(!isOpenMobile)}
              type="button"
              className="inline-flex items-center justify-center p-2 font-bold text-gray-400 bg-gray-800 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpenMobile ? (
                <svg
                  className="block w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              ) : (
                <svg
                  className="block w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${isOpenMobile ? "block" : "hidden"} md:hidden`}
        id="mobile-menu"
      >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {links.map((link, index) => (
            <React.Fragment key={index}>
              {link.dropDown ? (
                <div>
                  <p className="block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:text-white">
                    {link.text}
                  </p>
                  <div className="px-3 py-2">
                    {link.dropDown.map((item, index) => (
                      <Link
                        key={index}
                        to={item.url}
                        className="block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:text-white"
                      >
                        {item.text}
                      </Link>
                    ))}
                  </div>
                </div>
              ) : (
                <Link
                  to={link.url}
                  className="block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:text-white"
                >
                  {link.text}
                </Link>
              )}
            </React.Fragment>
          ))}
          <Link
            to={paths.CLIENT_PORTAL}
            className="block px-3 py-2 text-base font-bold text-center text-gray-300 border rounded-md hover:text-sky-500 bg-sky-500"
          >
            Portal Clientes
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
