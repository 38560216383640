import React, { useState } from "react";

const CollapsibleSection = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative w-full mb-5 overflow-hidden bg-white border-b border-blueGray-200">
      <div className="px-4 py-3 bg-transparent first:rounded-t">
        <button
          onClick={toggleCollapse}
          className="flex items-center justify-between w-full font-semibold transition-colors duration-300 ease-in-out text-sky-500 hover:text-sky-700"
        >
          <span>{title}</span>
          <span className="transition-transform transform">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className={`w-4 h-4 fill-current ${isOpen ? "rotate-180" : ""}`}
            >
              <path
                fill="#74C0FC"
                d={
                  isOpen
                    ? "M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                    : "M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                }
              />
            </svg>
          </span>
        </button>
      </div>
      <div
        className={`duration-300 ease-in-out ${isOpen ? "block" : "hidden"}`}
      >
        <div className="px-4 py-5 leading-relaxed text-blueGray-500">
          <p className="last:mb-2">{content}</p>
        </div>
      </div>
    </div>
  );
};

export default CollapsibleSection;
