import React from "react";
import { Link } from "react-router-dom";

const Hero = ({image, title, description, button, path }) => {
  return (
    <div className="relative flex justify-center bg-center bg-cover h-[34rem] md:h-[28rem] lg:h-[36rem] xl:h-[40rem]">
      <img
        src={image}
        alt="Background"
        className="absolute inset-0 object-cover w-full h-full"
      />
      <div
        className="absolute inset-0 bg-black opacity-50"
        style={{ zIndex: "-1" }}
      ></div>

      <div className="z-10 flex flex-col items-start justify-center max-w-6xl mx-auto text-white">
        <div className="container px-2 text-left md:px-4 sm:w-1/2">
          <h1 className="mb-4 text-3xl font-bold">{title}</h1>
          <p className="mb-8 text-sm md:text-md">{description}</p>
          <Link
            to={path}
            className="px-4 py-2 font-bold text-white transition duration-300 ease-in-out rounded-full bg-sky-500 hover:bg-sky-700"
            >
            {button}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
