import React from "react";
import { Helmet } from "react-helmet-async";

const HelmetWrapper = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content="Telorastreo es una empresa líder en el sector de rastreo, seguridad y control vehicular en Ecuador. Con años de experiencia en el mercado, nos hemos ganado la confianza de clientes comerciales y particulares gracias a nuestra dedicación a la excelencia y la satisfacción del cliente."
      />
      <meta
        name="keywords"
        content="Telorastreo, rastreo vehicular, seguridad vehicular, control vehicular, Ecuador"
      />
      <meta name="author" content="Telorastreo" />
      <meta
        property="og:title"
        content="Telorastreo - Tu Aliado en Rastreo, Seguridad y Control Vehicular en Ecuador"
      />
      <meta
        property="og:description"
        content="Descubre Telorastreo, la empresa líder en el sector de rastreo, seguridad y control vehicular en Ecuador. Con una sólida trayectoria y un enfoque centrado en la excelencia y la satisfacción del cliente, hemos ganado la confianza tanto de clientes comerciales como particulares."
      />
      <meta
        name="description"
        content="Explora nuestra tecnología vehicular avanzada que te brinda el control necesario para prevenir situaciones de inseguridad con tu vehículo. Mantén tu automóvil protegido en todo momento, desde la comodidad de tu mano."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.telorastreo.com/" />
      <meta
        property="og:image"
        content="https://www.telorastreo.com/logo.jpg"
      />{" "}
      <meta
        name="twitter:title"
        content="Telorastreo - Tu Aliado en Rastreo, Seguridad y Control Vehicular en Ecuador"
      />
      <meta
        name="twitter:description"
        content="Descubre Telorastreo, la empresa líder en el sector de rastreo, seguridad y control vehicular en Ecuador. Con una sólida trayectoria y un enfoque centrado en la excelencia y la satisfacción del cliente, hemos ganado la confianza tanto de clientes comerciales como particulares."
      />
      <meta
        name="twitter:image"
        content="https://www.telorastreo.com/logo.jpg"
      />{" "}
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

export default HelmetWrapper;
