import paths from "../../routes/paths"
const pricing = {
  title: "Escoge el plan que mejor se adapte a tus necesidades",
  description:
    "Cada plan incluye la instalación de equipo, escríbenos para encontrar el equipo que se adapte a tus necesidades.",
  button: "Contáctanos",
  path: (paths.WHATSAPP),
  description2: "¡Contáctanos para más información!",
  plans: [
    {
      title: "Básico",
      description: "Instalación de equipo $40",
      monthPrice: "$15",
      features: [
        "Equipo de GPS incluido",
        "Ubicación en tiempo real",
        "Alertas de encendido y apagado",
        "Alertas de exceso de velocidad",
        "Historial de recorridos 30 días",
        "Tiempo de contrato 24 meses",
      ],
    },
    {
      title: "Premium",
      description: "Instalación de equipo $40",
      monthPrice: "$30",
      features: [
        "Equipo de GPS incluido",
        "Ubicación en tiempo real",
        "Alertas de encendido y apagado",
        "Alertas de exceso de velocidad",
        "Alerta de desconexión de batería",
        "Geocercas y perímetros de seguridad",
        "Alertas de entrada y salida de geocercas",
        "Botón de pánico",
        "Desbloqueo y Bloqueo de motor",
        "Historial de recorridos 90 días",
        "Tiempo de contrato 24 meses",
      ],
    },
    {
      title: "Estándar",
      description: "Instalación de equipo $40",
      monthPrice: "$20",
      features: [
        "Equipo de GPS incluido",
        "Ubicación en tiempo real",
        "Alertas de encendido y apagado",
        "Alertas de exceso de velocidad",
        "Alerta de desconexión de batería",
        "Geocercas y perímetros de seguridad",
        "Alertas de entrada y salida de geocercas",
        "Historial de recorridos 30 días",
        "Tiempo de contrato 24 meses",
      ],
    },
  ],
};

export default pricing;
