import React from "react";
import { Link } from "react-router-dom";
import paths from "../routes/paths";
import HelmetWrapper from "../components/HelmetWrapper";

const NotFound = () => {
  return (
    <div className="w-full ct-docs-disable-nav-fixed">
      <HelmetWrapper
        title="Página no encontrada - Telorastreo"
        description="Lo sentimos, la página que estás buscando no se ha encontrado. Por favor, verifica la URL e intenta de nuevo. Si crees que esto es un error, por favor contáctanos."
      />
      <div className="h-screen overflow-hidden">
        <div className="absolute w-full h-full bg-cover bg-50"></div>
        <div className="container h-full px-4 mx-auto">
          <div className="flex flex-wrap items-center h-full -mx-4">
            <div className="relative w-full px-4 text-center">
              <h1 className="font-bold text-10 sm:text-12 tracking-875 text-blueGray-800">
                404
              </h1>
              <h4 className="mt-0 mb-2 text-3xl font-bold">
                Página no encontrada :(
              </h4>
              <p className="text-2xl font-normal leading-normal text-blueGray-500 mb-14">
                Ooooups! Parece que la página que buscas no esta disponible.
              </p>
              <Link
                to={paths.HOME}
                className="px-4 py-2 text-white rounded-lg bg-sky-500 hover:bg-sky-600"
              >
                Volver al inicio
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
