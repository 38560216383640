import React from "react";
import CollapsibleSection from "../components/Features/CollapsibleSection";
import ImageSection from "../components/Features/ImageSection";
import GlobalCard from "../components/Features/GlobalCard";
import bg_image from "../assets/images/bg_image.png";
import hero_contact from "../assets/images/hero_contact1.png";
import paths from "../routes/paths";
import faq from "../assets/data/faq";
import HelmetWrapper from "../components/HelmetWrapper";

const About = () => {
  const about = {
    company:
      "Te Lo Rastreo es una empresa líder en el sector de rastreo, seguridad y control vehicular en Ecuador. Con años de experiencia en el mercado, nos hemos ganado la confianza de clientes comerciales y particulares gracias a nuestra dedicación a la excelencia y la satisfacción del cliente.",
    mission:
      "Nuestra misión es proporcionar soluciones innovadoras y confiables de rastreo, seguridad y control vehicular que ayuden a proteger los activos y garantizar la tranquilidad de nuestros clientes. ",
  };

  const itemCard = {
    caption: "",
    title: "Nuestros Servicios",
    description:
      "Nuestros servicios incluyen rastreo satelital en tiempo real, soluciones de seguridad vehicular, gestión de flotas y más. Utilizando tecnología avanzada y un equipo de expertos en seguridad, ayudamos a nuestros clientes a proteger sus vehículos, mejorar la eficiencia operativa y garantizar la seguridad de sus conductores y cargas.",
    image: hero_contact,
    paths: paths.WHATSAPP,
    button: "Contacta a un asesor",
  };

  return (
    <div className="mx-2">
            <HelmetWrapper
        title="Acerca de Telorastreo - Rastreo, Seguridad y Control Vehicular en Ecuador"
        description="Descubre más sobre Telorastreo, la empresa líder en el sector de rastreo, seguridad y control vehicular en Ecuador. Conoce nuestra misión, visión y valores, así como nuestros servicios y cómo podemos ayudarte a proteger tus vehículos y mejorar la eficiencia operativa."
      />
      <div className="py-20">
        <ImageSection
          title="Nosotros"
          description={about.company}
          image={bg_image}
        />
      </div>
      <div className="container max-w-6xl mx-auto">
        <div className="text-center">
          <h2 className="block mb-8 font-sans text-2xl antialiased font-semibold leading-tight tracking-normal text-blue-gray-900 lg:text-4xl">
            Misión
          </h2>
          <p className="block font-sans text-xl antialiased font-normal leading-relaxed lg:pr-20 text-blue-gray-800">
            {about.mission}
          </p>
        </div>
      </div>
      <GlobalCard
        caption={itemCard.caption}
        title={itemCard.title}
        description={itemCard.description}
        image={itemCard.image}
        button={itemCard.button}
        path={itemCard.paths}
      />
      {/* <Hero
        image={hero_contact}
        title="Nosotros"
        description="Centrados en ofrecer soluciones innovadoras adaptadas a las necesidades específicas de nuestros clientes, respaldados por nuestra vasta experiencia en seguridad y por un equipo de profesionales altamente capacitados y motivados. Nos comprometemos a garantizar niveles de calidad sobresalientes mediante la búsqueda constante de la mejora continua."
        button="Contacto"
        path={paths.CONTACT}
      /> */}
      <div className="w-full my-24">
        <div className="container max-w-6xl px-4 mx-auto">
          <div className="flex flex-wrap justify-center mb-4 -mx-4">
            <div className="relative w-full px-4 text-center lg:w-8/12">
              <h6 className="mb-2 text-lg font-bold uppercase text-sky-500">
                FAQ{" "}
              </h6>
              <h2 className="mt-0 mb-1 text-4xl font-bold text-blueGray-700">
                Preguntas Frecuentes
              </h2>
              <p className="mt-2 mb-4 text-xl leading-relaxed text-blueGray-400">
                ¡Encuentra respuestas a las preguntas más comunes sobre Te Lo
                Rastreo y nuestros servicios! Si no encuentras lo que buscas, no
                dudes en ponerte en contacto con nuestro equipo de atención al
                cliente. Estaremos encantados de ayudarte.
              </p>
            </div>
          </div>
          <div className="flex flex-wrap -mx-4">
            <div className="relative w-full px-4">
              <div className="my-12">
                {faq.map((item, index) => (
                  <React.Fragment key={index}>
                    <CollapsibleSection
                      title={item.title}
                      content={item.content}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
