import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const ImageCard = ({ title, description, items }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextSlide = (currentSlide + 1) % items.length;
      setCurrentSlide(nextSlide);
    }, 5000); 
    
    return () => clearInterval(interval);
  }, [currentSlide, items.length]);

  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="px-8 pt-20 pb-20">
      <div className="flex flex-col items-center mb-16">
        <h1 className="block antialiased tracking-normal font-sans text-4xl font-semibold leading-[1.3] text-blue-gray-900 text-center mb-2">
          {title}
        </h1>
        <p className="block antialiased font-sans text-xl leading-relaxed text-inherit mb-3 w-full text-center font-normal !text-gray-500 lg:w-10/12">
          {description}
        </p>
      </div>
      <div className="container mx-auto !rounded-lg bg-slate-900 bg-center py-10 lg:px-16">
        <div className="relative flex w-full h-full overflow-x-hidden">
          {items.map((item, index) => (
            <div
              key={index}
              className="flex-none inline-block w-full h-full"
              style={{
                transform: `translateX(-${currentSlide * 100}%)`,
                transition: "transform 0.5s ease", 
              }}
            >
              <div className="!relative flex grid-cols-1 flex-col-reverse gap-6 px-10 py-14 md:grid md:grid-cols-5  md:gap-14 ">
                <div className="flex flex-col items-start justify-center col-span-3">
                  <p className="block mb-5 font-sans text-xl antialiased font-normal text-white">
                    {item.comment}
                  </p>
                  <p className="block font-sans text-sm antialiased font-medium leading-normal text-white uppercase">
                    <span className="font-normal opacity-60">
                      {item.author}
                    </span>
                  </p>
                  <Link
                    className="p-2 mt-4 font-bold text-center bg-slate-100 rounded-3xl hover:bg-slate-300"
                    to={item.path}>
                    {item.button}
                  </Link>
                </div>
                <div className="col-span-2 flex w-full shrink-0 md:!justify-end">
                  <img
                    src={item.image}
                    alt="client"
                    className="object-contain w-2/4 h-full md:w-auto"
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="absolute bottom-0 z-50 flex w-20 h-5 gap-2 left-16 -translate-x-2/4 md:left-2/4">
            {items.map((_, index) => (
              <span
                key={index}
                className={`block h-1 w-10 cursor-pointer transition-all content-[''] bg-white ${
                  index === currentSlide ? "bg-blue-500" : ""
                }`}
                onClick={() => handleIndicatorClick(index)}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
