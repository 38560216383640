import React from "react";
import google_play from "../../assets/images/google_play.png";
import paths from "../../routes/paths";

const MobileSection = ({ image, item }) => {
  return (
    <div> 
      <section className="px-8 py-20">
        <div className="grid items-center max-w-6xl grid-cols-1 gap-12 mx-auto lg:grid-cols-2">
          <img
            src={image}
            alt="Mobile"
            className="w-1/2 col-span-1 mx-auto lg:w-10/12"
          />
          <div className="max-w-lg col-span-1 px-4 mx-auto lg:px-0">
            <h2 className="block antialiased tracking-normal font-sans text-4xl font-semibold leading-[1.3] text-blue-gray-900 mb-4">{item.title}</h2>
            <p className="block antialiased font-sans font-normal text-inherit mb-5 px-4 text-left text-xl !text-gray-500 lg:px-0">{item.description}</p>
            <div className="grid grid-cols-2 col-span-2 gap-5 ">
              {item.items.map((subItem, index) => (
                <div
                  key={index}
                  className="relative flex flex-col text-gray-700 bg-transparent shadow-none bg-clip-border rounded-xl"
                >
                  <div className="grid p-6 px-0">
                    <h2 className="block antialiased tracking-normal font-sans text-4xl font-semibold leading-[1.3] text-blue-gray-900 mb-2">{subItem.title}</h2>
                    <p className="block font-sans text-base antialiased font-normal leading-relaxed text-inherit">{subItem.description}</p>
                  </div>
                </div>
              ))}
              <a target="_blank" href={paths.PLAYSTORE}>
                <img src={google_play}
                  alt="Google Play"
                  className="w-1/2 col-span-1 transition duration-300 ease-in-out delay-150 lg:w-10/12 hover:-translate-y-1 hover:scale-110"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MobileSection;