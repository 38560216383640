import React from "react";

const Stepper = ({ title, description, items }) => {
  return (
    <div className="max-w-xl p-4 mx-auto mt-10">
      <h2 className="mb-8 text-3xl font-bold font-heading lg:text-4xl">
        {title}
      </h2>
      <p class="text-gray-600 mb-8">{description}</p>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <div className="flex">
            <div className="flex flex-col items-center mr-4">
              <div>
                <div className="flex items-center justify-center w-10 h-10 border-2 rounded-full border-sky-500">
                  {index === items.length - 1 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-6 h-6 text-sky-500"
                    >
                      <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-6 h-6 text-sky-500"
                    >
                      <path d="M12 5l0 14"></path>
                      <path d="M18 13l-6 6"></path>
                      <path d="M6 13l6 6"></path>
                    </svg>
                  )}
                </div>
              </div>
              <div className="w-px h-full bg-gray-300 "></div>
            </div>
            <div className="pt-1 pb-8">
              <p className="mb-2 text-xl font-bold text-gray-900 ">
                {index + 1}. {item.step}
              </p>
              <p className="text-gray-600 ">
                {item.description}
              </p>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Stepper;
