import React from "react";
import pricing from "../../assets/data/pricing";
import { Link } from "react-router-dom";

const Pricing = () => {
  return (
    <div className="container w-full p-4 mx-auto">
      <div className="py-12 bg-blueGray-100">
        <div className="flex flex-col justify-center place-items-center">
          <div className="relative flex flex-col items-center justify-center w-full max-w-xl px-4 mb-6 ">
            <h2 className="mt-0 text-3xl font-bold text-center">
              {pricing?.title}
            </h2>
            <p className="mt-2 mb-4 text-lg leading-relaxed text-center text-blueGray-400">
              {pricing?.description}
            </p>
            <div></div>
          </div>
          <div className="relative w-full px-4 mx-auto">
            <div className="block">
              <div className="grid grid-cols-1 gap-4 place-items-center md:grid-cols-3">
                {pricing?.plans.map((plan, index) => (
                  <div className="relative flex justify-center w-full px-4 transition duration-300 ease-in-out delay-150 hover:-translate-y-1 hover:scale-110">
                    <div className="relative flex flex-col w-full min-w-0 mb-6 text-center break-words bg-white border rounded-lg shadow-lg border-sky-500 ">
                      <div className="px-5 py-3 bg-transparent border-b first:rounded-t border-blueGray-200">
                        <h6 className="my-2 font-bold">{plan.title}</h6>
                      </div>
                      <div className="flex-auto px-4 py-5">
                        <div className="mt-0 mb-0 text-5xl font-bold">
                          {plan.monthPrice}
                        </div>
                        <span> por mes </span>
                        <ul className="mt-6 mb-0 list-none">
                          {plan.features.map((feature, index) => (
                            <li className="flex items-center justify-center py-1 text-blueGray-500">
                              <span className="mr-2 text-lightBlue-500">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-3.5 w-3.5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  stroke="currentColor"
                                >
                                  <path
                                    fill="#f8f9fc"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  ></path>
                                </svg>
                              </span>
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="relative flex flex-col items-center justify-center w-full max-w-xl px-4 mb-6 ">
            <p className="mt-2 mb-4 text-lg leading-relaxed text-center text-blueGray-400">
              {pricing?.description2}
            </p>
            <div>
              <Link
                className="inline-block px-6 py-2 mr-2 text-sm font-bold text-white uppercase align-middle transition-all duration-150 ease-in-out border border-solid rounded-md shadow outline-none focus:outline-none last:mr-0 bg-sky-500 border-sky-500 hover:bg-sky-700 active:bg-sky-600 active:border-sky-600 hover:shadow-lg"
                to={pricing?.path}
              >
                {pricing?.button}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
