import React from "react";
import BenefitsSection from "../components/Features/BenefitsSection";
import benefits from "../assets/data/benefits";
import HelmetWrapper from "../components/HelmetWrapper";
const Benefits = () => {
  return (
    <div>
      <HelmetWrapper
        title="Beneficios - Telorastreo"
        description="Descubre los beneficios de elegir Telorastreo para tus necesidades de rastreo vehicular, seguridad y control. Conoce cómo podemos ayudarte a proteger tus activos y mejorar la eficiencia de tu flota."
      />
      <BenefitsSection
        caption={benefits.caption}
        title={benefits.title}
        description={benefits.description}
        items={benefits.items}
      />
    </div>
  );
};
export default Benefits;
